import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
export default function Experiences() {
    return (
        <div>
            <VerticalTimeline>
            <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'transparent', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(199,111,240)' }}
                    date="June 2024 - present | Part Time"
                    iconStyle={{ background: 'rgb(199,111,240)', color: '#fff' }}

                >
                    <h3 className="vertical-timeline-element-title">Brain Research Consultant</h3>
                    <h4 className="vertical-timeline-element-subtitle">WorldQuant</h4>
                    <p>
                        Research and Development, Alpha Generation, Quantative Finance &nbsp;
                        <br />
                        Exposure: Quant, Alpha Generation, Quant Finance.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'transparent', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(199,111,240)' }}
                    date="March 2021 - present"
                    iconStyle={{ background: 'rgb(199,111,240)', color: '#fff' }}

                >
                    <h3 className="vertical-timeline-element-title">Software Development Engineer 2</h3>
                    <h4 className="vertical-timeline-element-subtitle">DataCore Softwares</h4>
                    <p>
                        Research and Development &nbsp;
                        <br />
                        Exposure: Powershell, C#,  DotNet, Nunit, Git,
                        WPF, WCF, Dialogflow, Grafana, Nodejs, Postman, Nunit.

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="August 2020 - March 2021"
                    contentStyle={{ background: 'transparent', color: '#fff' }}
                    iconStyle={{ background: 'rgb(75,47,105)', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Program Analyst Trainee</h3>
                    <h4 className="vertical-timeline-element-subtitle">Cognizant</h4>
                    <p>
                        Cloud technology, Analyst and Automation
                        &nbsp;
                        <br />
                        Exposure: Docker, Java, Shell Scripting, Azure.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="March 2019 - May 2019"
                    contentStyle={{ background: 'transparent', color: '#fff' }}
                    iconStyle={{ background: 'rgb(75,47,105)', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">GSSOC'19</h3>
                    <h4 className="vertical-timeline-element-subtitle">GirlScript  Summer of Code </h4>
                    <p>
                        Open Source Contributor
                        &nbsp;
                        <br />
                        Exposure: Python, GoogleDrive API
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="June 2018 - Dec 2018"
                    contentStyle={{ background: 'transparent', color: '#fff' }}

                    iconStyle={{ background: 'rgb(75,47,105)', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Summer Intern</h3>
                    <h4 className="vertical-timeline-element-subtitle">FNplus Tech</h4>
                    <p>
                        Developer Advocate
                        &nbsp;
                        <br />
                        Exposure: JavaScript, Googlecloud, Nodejs.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}
