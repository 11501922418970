import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

function ProjectList({ title, projects }) {
    const linkStyle = {
        textDecoration: "none", // Remove underline
        color: "white", // Change color to white
        fontSize: "1.2rem",
        transition: "color 0.3s ease",
    };
    const handleMouseEnter = (event) => {
        event.target.style.color = "#a682b3";
        event.target.style.fontSize = "1.5rem"
    };

    const handleMouseLeave = (event) => {
        event.target.style.color = "white";
        event.target.style.fontSize = "1.2rem"
    };
    return (
        <Card className="project-card-view">
            <Card.Body>
                <br />
                <strong className="purple"> <Card.Title>{title}</Card.Title></strong>
                <br />
                <ListGroup variant="flush" style={{ backgroundColor: "transparent" }}>
                    {projects.map((project, index) => (
                        <ListGroup.Item key={index} style={{ backgroundColor: "transparent" }}>
                            <a
                                href={project.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={linkStyle}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {project.title}
                            </a>
                            {"\n"}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    );
}

export default ProjectList;
