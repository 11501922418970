import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import ProjectList from "./ProjectList";
import Particle from "../Particle";

// Dynamic import of images
const importAll = (r) => r.keys().reduce((acc, item) => {
  acc[item.replace("./", "")] = r(item);
  return acc;
}, {});

const images = importAll(require.context("../../Assets/Projects", false, /\.(png|jpe?g|svg|webp)$/));

const projects = [
  { title: "URL Shortner", link: "https://github.com/sauravjaiswalsj/MicroUrl" },
  { title: "FyndCare", link: "https://www.linkedin.com/company/fyndcare/" },
  { title: "Signature Encryption", link: "https://github.com/sauravjaiswalsj/paper" },
  { title: "Random Thoughts", link: "https://thoughtmix.vercel.app/" },
  { title: "GoodJS", link: "https://github.com/sauravjaiswalsj/Everyday-Javascript" },
  { title: "Movie Seating App", link: "https://sitemovie.netlify.app" },
  { title: "Todo list", link: "https://sauravjaiswalsj.github.io/todo-list/" },
  { title: "Background Color FLipper", link: "https://backc.netlify.app" },
  { title: "Web Drum", link: "https://webdrum.netlify.app" },
];

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["quillpad.png"]}
              isBlog={false}
              title="QuillPad"
              description="A sleek and intuitive notepad application. Seamlessly create, open, and save files while effortlessly performing operations like cut, copy, paste, undo, and redo. Tech Used: C++/QT"
              ghLink="https://github.com/sauravjaiswalsj/Notepad-QT"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["filmfever.png"]}
              isBlog={false}
              title="FilmFever"
              description="FilmFever (https://filmfever.vercel.app/): A react app for captivating movies. Discover, explore, and indulge in a curated selection of interesting and popular films. Tech Used: ReactJS"
              ghLink="https://github.com/sauravjaiswalsj/FilmFever/"
              demoLink="https://filmfever.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["githubfinder.png"]}
              isBlog={false}
              title="Github Finder"
              description="A react app built using react-query and react-router to find github user and their profile related information such as repo. Sorts the repos based on the stars."
              ghLink="https://github.com/sauravjaiswalsj/Github-Finder"
              demoLink="https://findgituser.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["botsearch.png"]}
              isBlog={false}
              title="BOT Search"
              description="BOT Search using whatsapp and twilio and Google custom-search API
                          Server
                          Used ngrok to deploy
                          ./ngrok http 3000
                          /usr/local/bin
                          "
              ghLink="https://github.com/sauravjaiswalsj/backend-Projects/tree/main/WhatsAppSearchBot"
            // demoLink="https://filmfever.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["leetcode.png"]}
              isBlog={false}
              title="DSA Tracker Chrome Extension"
              description="Developed a Chrome extension to help users track their daily progress in Data Structures and Algorithms practice. This tool enables users to set goals, log problems to excel sheet, and visualize their progress over time, enhancing their consistency and motivation in DSA learning."
              ghLink="https://github.com/sauravjaiswalsj/DSA-Tracker-Extension"
            // demoLink="https://filmfever.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["dotmode.webp"]}
              isBlog={false}
              title=" DotMoDe, Assistive Tech"
              description="This project aims to solve accessibility, usability, affordability for people with disabilities. It works by attaching itself to any mobile device and contextually provide Braille script on the surface. Worked with a team & tested with a focus group of visually impaired people."
              ghLink="https://github.com/sauravjaiswalsj/DSA-Tracker-Extension"
             demoLink="https://www.youtube.com/watch?v=JagqE128LQM"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["auth.jpeg"]}
              isBlog={false}
              title="Authentication System Design"
              description="An authentication system build using express js consists of a user login and register system. The user register to the application for the first time. Their information is captured and stored in the db. User information sucn as password is encrypted and then store. When the user login's next time with the credentials that cred is verified and if successful, the user will be redirected to the dashboard of the application"
              ghLink="https://github.com/sauravjaiswalsj/MernAuth"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["Library.png"]}
              isBlog={false}
              title="Library System Design"
              description="A Library Management System is basically used to manage the book record of a Library, where a librarian can view all the available books, add new books, delete books, issue books, and return books.
                        Functionalities: A librarian can do the following things with this Library Management System:
                        Display Available Books
                        Add New Books
                        Delete Books
                        Update Books"
              ghLink="https://github.com/sauravjaiswalsj/backend-Projects/tree/main/LibraryManagement"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["WeatherApp.png"]}
              isBlog={false}
              title="ClimateDaily"
              description="ClimateDaily developed using reactjs and react-query. It provides users with real-time or forecasted weather information for a specific location or multiple locations"
              ghLink="https://github.com/sauravjaiswalsj/Weather-App"
              demoLink="https://climatedaily.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={images["oldfolio.png"]}
              isBlog={false}
              title="Portfolio"
              description="Portfolio developed using vanila Javascript and css."
              ghLink="https://github.com/sauravjaiswalsj/sauravjaiswalsj.github.io"
              demoLink="https://sauravjaiswalsj.github.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={false}
              isBlog={false}
              title="Search Crawler"
              description="A Chrome Extension that scans the open webpage and provides intelligent links to similar content, eliminating the need for extensive Google searches."
              ghLink="https://gitlab.com/sauravjaiswalsj/searchcrawler#"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectList title="More Projects" projects={projects} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
