import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiCsharp,
  SiPowershell,
  SiExpress,
  SiAmazonec2,
  SiVite,
  SiMysql
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVite />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPowershell />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonec2 />
      </Col>
    </Row>
  );
}

export default Techstack;


/*


function Techstack() {
  return (
    <Row xs={2} md={4} lg={6} className="g-3 justify-content-center">
      {[
        { icon: <DiJava />, name: "Java" },
        { icon: <DiJavascript1 />, name: "JavaScript" },
        { icon: <DiNodejs />, name: "Node.js" },
        { icon: <DiReact />, name: "React" },
        { icon: <DiMongodb />, name: "MongoDB" },
        { icon: <SiNextdotjs />, name: "Next.js" },
        { icon: <SiExpress />, name: "Express" },
        { icon: <SiCsharp />, name: "C#" },
        { icon: <DiGit />, name: "Git" },
        { icon: <SiMysql />, name: "MySQL" },
        { icon: <SiFirebase />, name: "Firebase" },
        { icon: <SiRedis />, name: "Redis" },
        { icon: <SiVite />, name: "Vite" },
        { icon: <DiPython />, name: "Python" },
        { icon: <CgCPlusPlus />, name: "C++" },
        { icon: <SiPowershell />, name: "PowerShell" },
        { icon: <SiAmazonec2 />, name: "Amazon EC2" },
      ].map((tech, index) => (
        <Col key={index} className="tech-icons text-center">
          {tech.icon}
          <p className="mt-2 mb-0" style={{ fontSize: '0.8rem' }}>{tech.name}</p>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
*/