import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, I am <span className="purple">Saurav</span> Jaiswal
            from <span className="purple">India.</span>
            <br />
            <br />
            I work as a Software Development Engineer <a href="https://www.datacore.com/" className="purple" style={{ textDecoration: "none" }}>@DataCore Software</a>
            <br />
            Additionally, I've completed my engineering in <span className="purple">Computer Science</span> graduated in the year 2020.
            <br />
            <br />
            Beyond my professional experiences, I actively contribute to the tech community. I participated as a Contributor at <span className="purple">GSSoC'19</span>, where I worked on Python-based Command Line Interfaces.
            I also served as a <span className="purple">Developer Advocate</span> at Fnplus Club and contributed to talentmeet.org as a <span className="purple">Speaker and Volunteer.</span>
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Let's connect and weave our talents together!"{" "}
          </p>
          {/* <footer className="blockquote-footer">saurav</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
